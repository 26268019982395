<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header title="Property Selector">
                    <template v-slot:description>
                        <div>import { HbPropertySelector } from 'hummingbird-aviary';</div>
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <hb-card class="mt-4 mb-6">

            <template v-slot:title>
                <span class="pr-3">Default Property Selector with default '.hb-primary-lighter' Wrapper Color</span>
                <span class="hb-text-light hb-font-body font-weight-regular">The colored wrapper should be flush with the right side of the browser window and/or content area, there should be 24px on the left side margin (i.e. flush to our standard left side page margin), there should only be 12px of white space margin on the top and bottom between the elements that go before and after the selector - except where there is a data table header with text directly below, which then should have 24px of bottom under the property selector until the beginning of the data table header text (see examples further down the page for reference). This is because design has determined that text directly below the property selector needs more separation otherwise it does not look good.</span>
            </template>

            <div class="ml-6">
                <HbPropertySelector
                    v-model="property1"
                    :items="items"
                />
            </div>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;HbPropertySelector
    v-model="property1"
    :items="items"
/>
</pre>
            </hb-card>

        </hb-card>

        <hb-card class="mt-4 mb-6">

            <template v-slot:title>
                Property Selector while Loading + Code
            </template>

            <div class="ml-6">
                <HbPropertySelector
                    v-model="property2"
                    :items="items"
                    loading
                />
            </div>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;HbPropertySelector
    v-model="property2"
    :items="items"
    loading
/>
</pre>
            </hb-card>

        </hb-card>

        <hb-card class="mt-4 mb-6">

            <template v-slot:title>
                Property Selector By Itself + Code
            </template>

            <div class="pa-4">
                <HbPropertySelector
                    v-model="property3"
                    :items="items"
                    :wrapper="false"
                />
            </div>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;HbPropertySelector
    v-model="property3"
    :items="items"
    :wrapper="false"
/>
</pre>
            </hb-card>

        </hb-card>

        <hb-card class="mt-4 mb-6">

            <template v-slot:title>
                Property Selector Normal Margin Between Elements
            </template>

            <div class="mt-3 ml-6">
                <hb-header>
                    <hb-page-header
                        title="Page Title Here"
                        description="Page description here"
                    >
                    </hb-page-header>
                </hb-header>
                <HbPropertySelector
                    v-model="property5"
                    :items="items"
                />
                <div class="my-3 mr-6">
                    <hb-empty-state message="Normal 12px separation between this element and the HbPropertySelector above."></hb-empty-state>
                </div>
            </div>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;div class="mt-3 ml-6">
    &lt;hb-header>
        &lt;hb-page-header
            title="Page Title Here"
            description="Page description here"
        >
        &lt;/hb-page-header>
    &lt;/hb-header>
    &lt;HbPropertySelector
        v-model="property5"
        :items="items"
    />
    &lt;div class="my-3 mr-6">
        &lt;hb-empty-state message="Normal 12px separation between this element and the HbPropertySelector above.">&lt;/hb-empty-state>
    &lt;/div>
&lt;/div>
</pre>
            </hb-card>

        </hb-card>

        <hb-card class="mt-4 mb-6">

            <template v-slot:title>
                Property Selector with Text Directly Below (i.e. 24px of margin below the property selector instead of the normal 12px)
            </template>

            <div class="ml-6">
                <hb-header>
                    <hb-page-header
                        title="Page Title Here"
                        description="Page description here"
                    >
                    </hb-page-header>
                </hb-header>
                <HbPropertySelector
                    v-model="property5"
                    :items="items"
                />

                <div class="mt-4 mr-6 mb-6">
                    <hb-data-table-header>
                        <template v-slot:description>
                            This is a description for what this table is used for and how to use it.
                        </template>
                        <template v-slot:actions>
                            <hb-btn small color="secondary" @click="dialog = true">Add New Items</hb-btn>
                            <hb-modal v-model="dialog" size="medium" title="Add New Items">
                                <template v-slot:subheader>You can put something here or not.</template>
                                <template v-slot:content>
                                    <div class="my-6 d-flex justify-center">Amazing content goes here.</div>
                                </template> <template v-slot:actions>
                                    <hb-btn color="primary" @click="dialog = false">Save</hb-btn>
                                </template>
                            </hb-modal>
                        </template>
                    </hb-data-table-header>

                    <hb-data-table
                        :headers="headers"
                        :items="items2"
                    >
                        <template v-slot:item.name="{ item }">
                            <hb-icon color="#101318">mdi-account</hb-icon> {{ item.name }}
                        </template>
                        <template v-slot:item.category="{ item }">
                            {{ item.category }}
                        </template>
                        <template v-slot:item.misc="{ item }">
                            {{ item.misc }}
                        </template>
                        <template v-slot:item.other="{ item }">
                            {{ item.other }}
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <hb-menu options class="d-flex justify-end">
                                <v-list>

                                    <v-list-item :ripple="false" @click="setNotification('View')">
                                        <v-list-item-title>View</v-list-item-title>
                                    </v-list-item>

                                    <v-list-item :ripple="false" @click="setNotification('Delete')">
                                        <v-list-item-title>Delete</v-list-item-title>
                                    </v-list-item>

                                </v-list>
                            </hb-menu>
                        </template>
                        
                    </hb-data-table>
                </div>
            </div>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;div class="ml-6">
    &lt;hb-header>
        &lt;hb-page-header
            title="Page Title Here"
            description="Page description here"
        >
        &lt;/hb-page-header>
    &lt;/hb-header>
    &lt;HbPropertySelector
        v-model="property5"
        :items="items"
    />

    &lt;div class="mt-4 mr-6 mb-6">
        &lt;hb-data-table-header>
            &lt;template v-slot:description>
                This is a description for what this table is used for and how to use it.
            &lt;/template>
            &lt;template v-slot:actions>
                &lt;hb-btn small color="secondary" @click="dialog = true">Add New Items&lt;/hb-btn>
                &lt;hb-modal v-model="dialog" size="medium" title="Add New Items">
                    &lt;template v-slot:subheader>You can put something here or not.&lt;/template>
                    &lt;template v-slot:content>
                        &lt;div class="my-6 d-flex justify-center">Amazing content goes here.&lt;/div>
                    &lt;/template> &lt;template v-slot:actions>
                        &lt;hb-btn color="primary" @click="dialog = false">Save&lt;/hb-btn>
                    &lt;/template>
                &lt;/hb-modal>
            &lt;/template>
        &lt;/hb-data-table-header>

        &lt;hb-data-table
            :headers="headers"
            :items="items2"
        >
            &lt;template v-slot:item.name="{ item }">
                &lt;hb-icon color="#101318">mdi-account&lt;/hb-icon> <span v-pre>{{ item.name }}</span>
            &lt;/template>
            &lt;template v-slot:item.category="{ item }">
                <span v-pre>{{ item.category }}</span>
            &lt;/template>
            &lt;template v-slot:item.misc="{ item }">
                <span v-pre>{{ item.misc }}</span>
            &lt;/template>
            &lt;template v-slot:item.other="{ item }">
                <span v-pre>{{ item.other }}</span>
            &lt;/template>
            &lt;template v-slot:item.actions="{ item }">
                &lt;hb-menu options class="d-flex justify-end">
                    &lt;v-list>

                        &lt;v-list-item :ripple="false" @click="setNotification('View')">
                            &lt;v-list-item-title>View&lt;/v-list-item-title>
                        &lt;/v-list-item>

                        &lt;v-list-item :ripple="false" @click="setNotification('Delete')">
                            &lt;v-list-item-title>Delete&lt;/v-list-item-title>
                        &lt;/v-list-item>

                    &lt;/v-list>
                &lt;/hb-menu>
            &lt;/template>
            
        &lt;/hb-data-table>
    &lt;/div>
&lt;/div>
</pre>
            </hb-card>

        </hb-card>

        <hb-card title="Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <!--
        <hb-card title="Slots" class="my-6 pb-1">
            <hb-data-table
                :headers="slotHeaders"
                :items="slotItems"
                class="pa-6"
                >
            </hb-data-table>
        </hb-card>

        <hb-card title="Events" class="my-6 pb-1">
            <hb-data-table
                :headers="eventHeaders"
                :items="eventItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>
        -->

    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemPropertySelector",
        data: function() {
            return {
                property1: '',
                property2: 'Property 1',
                property3: '',
                property4: '',
                property5: '',
                items: [ 'Property 1', 'Property 2', 'Really Long Property Example with Ellipses when Overflowing' ],
                propHeaders: [
                    { text: 'Name', value: 'name', width: '140' },
                    { text: 'Type', value: 'type' },
                    { text: 'Default', value: 'default', width: '110' },
                    { text: 'Description', value: 'description' },
                ],
                propItems: [
                    { name: 'wrapper', type: 'boolean', default: 'true', description: 'If changed to false, this will turn off the default primary colored wrapper and padding.' },
                    { name: 'placeholder', type: 'string', default: 'Select Property', description: 'Use this if you need to change the default placeholder text.' },
                    { name: 'loading', type: 'boolean', default: 'false', description: 'If changed to true, shows a looping loader until you manually or programmatically turn the value to false.' },
                    { name: 'full', type: 'boolean', default: 'false', description: 'If changed to true, changes the input max-width from the standard 370px to no limit/full width.' },
                ],
                dialog: false,
                headers: [
                    { text: 'Name', value: 'name' },
                    { text: 'Email', value: 'category' },
                    { text: 'Example Value', value: 'misc' },
                    { text: 'Another Value', value: 'other' },
                    { text: '', value: 'actions', align: 'right', sortable: false },
                ],
                items2: [
                    { name: 'Albus', category: 'Thisisatest898@storageoutlet.net', misc: '8001', other: '1001' },
                    { name: 'Voldemort', category: 'Thisisatest899@storageoutlet.net', misc: '9001', other: '2001' },
                    { name: 'Tom', category: 'Thisisatest8901@storageoutlet.net', misc: '10001', other: '30001' }
                ],
                /*
                slotHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                slotItems: [
                ],
                eventHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                eventItems: [
                ],
                */
            };
        },
    }
</script>

<style scoped>

</style>
